import { Seo } from "gatsby-plugin-wpgraphql-seo";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Page } from "../../../config/Pages";

import { usePathToPage } from "../../helpers/clientSide/usePathToPage";
import RVOContainer from "../02-molecules/RVOContainer/RVOContainer";

const use404PageClasses = makeStyles((theme: Theme) =>
  createStyles({
    headings: {
      width: "50%",
      textAlign: "center",
      margin: "auto",
      [theme.breakpoints.down("md")]: {
        width: "70%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    buttonDiv: {
      padding: theme.spacing(2, 0),
    },
    button: {
      margin: theme.spacing(0, 1, 1, 1),
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  })
);

const NotFoundPage = (): JSX.Element => {
  const { t } = useTranslation();

  const classes = use404PageClasses();
  const pathToPage = usePathToPage();
  return (
    <Box>
      <Seo title={t("general.pageNotFoundTitle")} />

      <RVOContainer paddingBottom={true}>
        <Box className={classes.headings}>
          <Typography component="h1" variant="h1">
            {t("general.pageNotFound")}
          </Typography>

          <Typography component="h4" variant="h5">
            {t("general.pageNotFoundSubtitle")}
          </Typography>

          <Box className={classes.buttonDiv}>
            <Button
              href={pathToPage(Page.Home)}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {t("404.buttonPrimary")}
            </Button>

            <Button
              href={pathToPage(Page.Wissen)}
              variant="contained"
              color="secondary"
              className={classes.button}
            >
              {t("404.buttonSecondary")}
            </Button>
          </Box>
        </Box>
      </RVOContainer>
    </Box>
  );
};

export default NotFoundPage;
